export default {
  "Вход в кабинет": "Login",
  Подразделения: "Divisions",
  "Все подразделения": "All (Divisions)",
  ПИНФЛ: "PINI",
  "Серия и номер документа": "(Document) Series and Number",
  "Место выдачи": "Issuance Place",
  "Адрес по прописке": "Registration Address",
  "Адрес проживания": "Residential Address",
  "Дополнительный контактный номер": "Additional Contact Number",
  Добавить: "Add",
  "Семейный статус": "Marital Status",
  Образование: "Education",
  "Опыт работы": "Experience",
  Ожидается: "Expected",
  "Сотрудники (HRM)": "Employees",
  Филиалы: "Branches",
  Должности: "Positions",
  Сотрудники: "Personnel",
  Доступы: "Access",
  "Компании (CRM)": "Companies",
  "Задачи (TASKS)": "Tasks",
  KPI: "KPI",
  "Общий KPI компании": "Overall KPI",
  Компании: "Companies",
  "Help Desk": "Help Desk",
  Отчетность: "Reports",
  Резиденты: "Residents",
  Выход: "Exit",
  Назад: "Back",
  "Добавить номер": "Add (Number)",
  "Добавить почту": "Add (Email)",
  "Уволить сотрудника": "Terminate (Employee)",
  "Назначить должность": "Assign (Position)",
  "Персональная информация": "Personal Info",
  Документы: "Documents",
  "Отпуска и отгулы": "Leave and Vacation",
  "История передвижения": "Progress History",
  "Все филиалы": "All (Branches)",
  "Архивированные филиалы": "Archived ",
  Филиал: "Branch",
  Регион: "Region",
  "Создать филиал": "Create",
  Изменить: "Edit",
  Архивировать: "Archive",
  "Поле для поиска": "Search",
  Восстановить: "Recover",
  "Архивированные подразделения": "Archived (Divisions)",
  "Создать отдел": "Create",
  "Кол-во сотрудников": "Worldwide employee number",
  "Все должности": "All (Positions)",
  "Архивированные должности": "Archived (Positions)",
  "Наименование должности": "Job",
  "Тип должности": "Type",
  "Тип сотрудника": "Employee type",
  "Создать должность": "Create",
  Руководитель: "Head manager",
  Сотрудник: "Employee",
  "Все сотрудники": "All (Employees)",
  ФИО: "Full Name",
  Подразделение: "Division",
  Должность: "Position",
  "Добавить сотрудника": "Add",
  Поиск: "Search",
  "По странам": "By Country",
  Выберите: "Choose",
  "Источник карточки": "Source",
  Приоритет: "Priority",
  Этапы: "Stages",
  "Просроченные карточки": "Overdue Cards",
  "Архивированные карточки": "Archived Cards",
  "Название компании": "Company",
  "Создать этап": "Create",
  Редактировать: "Edit",
  "Передвинуть влево": "Move Left",
  "Передвинуть вправо": "Move Right",
  "Срок прохождения": "Duration",
  день: "day",
  дня: "days",
  дней: "days",
  "Добавить компанию": "Add",
  Средний: "Medium",
  "Узнать детали": "View Details",
  "Последние изменения": "Recent Changes",
  "Создать рабочее пространство": "Create a workspace",
  "У вас отсутствуют созданные рабочие пространства.":
    "You are missing created workspaces.",
  "Пожалуйста, создайте рабочее пространство для начала работы.":
    "Please create a workspace to get started.",
  Низкий: "Low",
  Высший: "High",
  "Контактное лицо": "POC (Point of Contact)",
  Источник: "Source",
  "Прикрепленные сотрудники": "Attached employees",
  "Локация штаб-квартиры": "Headquarters location",
  "Количество офисов в мире": "Worldwide Office Number",
  "Доход (USD)": "Revenue (USD)",
  "Количество сотрудников в мире": "Worldwide Employee Number",
  "Веб-сайт": "Website",
  "Дата создания": "Date of Creation",
  "Текущий Этап": "Current Stage",
  "Важность компании": "Importance of the company",
  "Срок прохождения этапа": "Duration",
  Действия: "Actions",
  "История действий": "Action History",
  "Сменить этап": "Change Stage",
  "Продлить срок": "Extend Deadline",
  "Назначить сотрудника": "Assign Employee",
  "Добавить комментарий": "Comment",
  "Архивировать карточку": "Archive Card",
  "Выберите этап": "Select (Stage)",
  Отправить: "Send",
  "Создание этапа": "Create Stage",
  "Наименование этапа": "Stage",
  "Например, Проведение переговоров": "E.g., Conducting Negotiations",
  Закрыть: "Close",
  "Деятельность компании": "Company's activities",
  "Страна учредитель": "Founding country",
  "Скрыть детали": "Hide Details",
  Деятельность: "Activity",
  Пользователь: "User",
  Действие: "Action",
  Дата: "Date",
  Создан: "Created",
  Сохранить: "Save",
  "Ответственные сотрудники": "Officers-in-charge",
  "Написать комментарий": "Comment",
  Опубликовать: "Submit",
  "Подтвердите действие": "Confirm",
  "Вы точно хотите архивировать?": "Are you sure?",
  Да: "Yes",
  Нет: "No",
  "Переведен на этап": "Transferred to the stage",
  Комментировал: "Commented",
  Архивирован: "Archived",
  "Срок был проделен": "The deadline has been passed",
  "Назначен сотрудник": "Designated employee",
  "Прокомментируйте действие": "Comment",
  "Добавьте ИНН компании": "Add Entity TIN",
  ИНН: "TIN",
  "Дата регистрации": "Date of registration",
  "Название стратапа": "Stratap name",
  "ИНН стартапа (необязательное)": "Startup TIN (optional)",
  "Сумма инвестиций в стартап (USD)": "Amount invested in the startup (USD)",
  "Вы уверены?": "Are you sure?",
  "Выберите подразделение": "Select a division",
  "Название отдела": "Name",
  "Напишите отдел": "Write the department",
  "Руководитель отдела": "Department Head",
  "Количество сотрудников": "Employees Number",
  Введите: "Enter",
  Отмена: "Cancel",
  "Создание отдела": "Create Department",
  "Название филиала": "Name",
  "Выберите регион": "Select a region",
  "Создание филиала": "Create Branch",
  "Напишите филиал": "Write a branch",
  "Поле для ввода": "Input",
  "Название должности (на узбекском языке)": "Job (Uzbek)",
  "Выберите тип должности": "Select the type of position",
  "Создание должности": "Create Position",
  Отдел: "Department",
  "Выберите отдел": "Select a department",
  "Название должности (на английском)": "Job (English)",
  "Добавление сотрудника": "Add Employee",
  "Серия и номер паспорта": "(Passport) Series and Number",
  "Дата рождение": "Birth Date",
  год: "yy",
  месяц: "mm",
  Далее: "Next",
  "Дата рождения": "Date of birth",
  Пол: "Sex",
  Мужской: "Male",
  Женский: "Female",
  "Вы точно хотите уволить сотрудника?": "Are you sure?",
  Назначение: "Assignment",
  "Номер телефона": "Phone",
  Наименование: "Name",
  "ВАШИ РАБОЧИЕ ПРОСТРАНСТВА": "YOUR WORKSPACES",
  "Создать доску": "Create a board",
  "Добавить рабочее пространство": "Add a workspace",
  Фильтры: "Filters",
  "Добавить задачу": "Add",
  Фильтр: "Filter",
  "Очистить все": "Clear All",
  "Применить фильтры": "Apply Filters",
  "Добавление задачи": "Add Task",
  Название: "Title",
  Описание: "Description",
  "Срок задачи": "Deadline",
  "Нет срока": "No deadline",
  помесячно: "Monthly",
  "по декадам": "By decade",
  Период: "Period",
  "Тип отображения": "View Type",
  "по нарастающей": "Increasing",
  "Скачать excel": "Download excel",
  "ФИО сотрудника": "Full name of the employee",
  "Всего баллов": "Total points",
  "макс. бал.": "Max. Score",
  Балл: "Score",
  "максимум баллов": "Maximum Score",
  План: "Plan",
  Факт: "Actual",
  Разница: "Difference",
  Процент: "Percentage",
  "1 - количество компаний на текущем этапе":
    "0 - number of companies at current stage",
  "(3) - количество компаний, прошедших данный этап":
    "(3) - number of companies passed ",
  "Всего компаний": "Total Companies",
  Подсказка: "Hint",
  "Войти с": "Login with",
  "Единая система идентификации (OneID) предназначена для простого и безопасного доступа пользователей к различным информационным системам электронного правительства и государственных органов.":
    "OneID is designed for easy and secure user access to various e-government and government information systems.",
  Файл: "File",
  Удалить: "Delete",

  "Прикрепленные файлы": "Attached files",
  "Прикрепить файл": "Attach file",
  "Загрузить файлы": "Download files",
  "Прикрепил файл": "Attached file",
  "Прикреплённый файл удалён": "Attached file deleted",
  "Добавление компании": "Adding a company",
  "ИНН компании": "Company TIN",
  "Например Tetra Park": "For example, Tetra Park",
  "Направление деятельности": "Direction of activity",
  "Страна учредителя": "Founder's country",
  "Сумма экспорта": "Export amount",
  "Прикрепить скриншот": "Attach screenshot",
  "Категории тикетов": "Ticket categories",
  "Все категории тикетов": "All ticket categories",
  "Добавить новую категорию тикета": "Add new ticket category",
  "Наименование на узбекском": "Name uz",
  "Наименование на русском": "Name ru",
  "Наименование на английском": "Name en",
  "Дата завершения тикета": "Expired day",
  "Создать категорию": "Create category",
  "Категории часто задаваемых вопросов": "FAQ categories",
  "Все категории часто задаваемых вопросов": "All FAQ categories",
  "Добавить категорию часто задаваемых вопросов": "Add FAQ category",
  Статьи: "Articles",
  Шаблоны: "Templates",
  Содержание: "Content",
  "Все статьи": "All articles",
  "Добавить новую статью": "Add new article",
  Категория: "Category",
  "Создать статью": "Create article",
  "Изменить категорию": "Edit category",
  "Редактировать статью": "Edit article",
  "Все шаблоны": "All templates",
  "Добавить новый шаблон": "Add new template",
  "Редактировать шаблон": "Edit template",
  "Статус изменен на status_name": "Статус изменен на <b>{status_name}</b>",
  "Приоритет изменен на name": "Приоритет изменен на <b>{name}</b>",
  "Изменён ответственный сотрудник с old на new":
    "Изменён ответственный сотрудник с <b>{old}</b> на <b>{new}</b>",
  "Срок задачи изменён с old на new":
    "Срок задачи изменён с <b>{old}</b> на <b>{new}</b>",
  Тикеты: "Tickets",
  "Все тикеты": "All tickets",
  "Добавить новый тикет": "Add new Ticket",
  "Создание тикета": "Ticket create",
  Вакант: "Vacant",
  Возраст: "Age",
  лет: "years old",
  "Дни рождения": "Birthdays",
  "Дней осталось": "Days left",
  Сегодня: "Today",
  Завтра: "Tomorrow",
  "дней осталось": "days left",
  Специалист: "Specialist",
  Помощник: "Assistant",
  "Технический специалист": "Technical Specialist",
  "Нет данных": "No data",
  "Название отдела согласно штатному расписанию":
    "Department name according to staffing table",
  Заявитель: "Applicant",
  Простой: "Simple",
  Отклоненный: "Rejected",
  Успешный: "Success",
  "Тип этапа": "Type step",
  "Текущее подразделение": "Current division",
  Структура: "Structure",
  "Загрузить лиды через Excel": "Download leads via Excel",
  Госнаграды: "State awards",
  "У данного сотрудника пока отсутствуют госнаграды":
    "This employee does not have any government awards yet.",
  "У данного сотрудника пока отсутствуют сертификаты":
    "This employee does not have any certificates yet.",
  "Дата перевода": "Date of transfer",
  employed: "hired",
  dismissed: "fired",
  changed: "moved to another position",
  "История передвижений не найдена": "History of movements not found",
  "Информация не предоставлена": "No information provided",
  "Создать госнаграду": "Create a state award",
  "Редактировать госнаграду": "Edit a state award",
  "Удалить госнаграду": "Delete a state award",
  Год: "Year",
  "Все госнаграды": "All state awards",
  Комментарий: "Comment",
  "Электронная почта": "Email",
  Уведомления: "Notifications",
  "Вам поступила новая задача в модуле TASKS":
    "You have received a new task in the TASKS module",
  "Создана карточка, которая по названию имеет совпадение с ранее созданными другими карточками.":
    "<b>{name}</b> card has been created, which is identical in name to other previously created cards.",
  "Карточка была переведена на этап Rejected":
    "<b>{company}</b> card was transferred to the Rejected stage by <b>{name}</b>.",
  "Вы создаете карточку с компанией":
    "You create a card with a company which name matches other previously created cards. Are you sure you want to create a card with this name?",
  "Через 15 дней карточка переместится на этап Postponed":
    "After 15 days the card will move to the Postponed stage",
  "Через 3 дня карточка будет скрыта с данного этапа":
    "After 3 days the card will be hidden from this stage",
  Понятно: "Understandable",
  "Данную карточку нельзя переместить на другие этапы, так как карточка уже добавлена в CRM систему.":
    "This card cannot be moved to other stages, because the card has already been added to the CRM system.",
  "Чтобы переместить карточку на этап Lead, нужно сначала его взять в работу.":
    "To move a card to the Lead stage, you must first take it to work.",
  "Карточку с данного этапа можно перевести только на этап Contact.":
    "Card from this stage can only be transferred to the Contact stage.",
  "Карточку с данного этапа можно перевести только на этапы Lead и Rejected.":
    "Card from this stage can only be transferred to the Lead and Rejected stages.",
  "Карточку с данного этапа нельзя перевести на этап Contact.":
    "Card from this stage cannot be transferred to the Contact stage.",
  Модуль: "Module",
  "Название карточки": "Card title",
  "Программа Local 2 Global": "Local 2 Global Program",
  "Номер заявления": "Application number",
  "Все регионы": "All regions",
  "Дата (от)": "From date",
  "Дата (по)": "To date",
  "Статус заявления": "Application status",
  Очистить: "Clear",
  "Количество заявок": "Number of applications",
  "Дата подачи": "Date of submission",
  Статус: "Status",
  Черновик: "Draft",
  "На расмотрении": "Under consideration",
  "Отказано в рассмотрении": "Refused to consider",
  "Возвращено на доработку": "Returned for revision",
  Подтвержден: "Confirmed",
  "Нет записей": "No records",
  Подтвердить: "Confirm",
  Отозвать: "Revoke",
  Скачать: "Download",
  "Сведения о компании": "Company details",
  "Дата регистрации/Номер регистрации": "Registration date/Registration number",
  "Юридический адрес": "Legal adress",
  "ФИО директора": "Name of director",
  Контакты: "Contacts",
  "Номер телефона директора": "Director's phone number",
  "Номер телефона ответственного лица": "Responsible person's phone number",
  "Электронная почта для отправки уведомлений":
    " e-mail address to send the notification",
  "Тип компании": "Company type",
  Сервисная: "Service",
  "Виды разрабатываемого программного обеспечения":
    "Types of software to be developed",
  "Языки программирования": "Programming languages",
  "Базы данных": "Database",
  Фреймворки: "Frameworks",
  Продуктовая: "Product",
  "Краткое описание продукта": "Brief description of product",
  "Предназначение продукта": "Product purpose",
  "Образовательное учреждение": "Educational institution",
  "Перечень образовательных курсов": "List of educational courses",
  "Наличие филиалов": "Branch availability",
  Консалтинговая: "Consulting",
  "Хостинг и регистратор доменов": "Hosting and domain registrar",
  Кибербезопасность: "Cybersecurity",
  "Деятельность венчурного финансирования и акселерации":
    "Venture financing and acceleration activities",
  "Услуги киберспорта": "Esports services",
  "Услуги в сфере мультимедиа и дизайна": "Multimedia and design services",
  "Количество филиалов": "Number of branches",
  Специальность: "Speciality",
  "Количество единиц": "Number of units",
  "Сохранить как черновик": "Save as a draft",
  "Информация о сотрудниках": "Employee information",
  "Финансовая информация": "Financial information",
  "Доход от экспортных операций в течение последнего года":
    "Income from export operations during the last year",
  usd: "USD",
  "Список стран, куда был выполнен экспорт услуг/продуктов":
    "List of countries where services/products were exported",
  "Доход от оказанных услуг в течение последнего года":
    "Income from services provided during the last year",
  "тыс. сум": "thousand UZS",
  Есть: "Yes",
  "Краткое описание деятельности компании":
    "Brief description of the company's activities",
  "Описание продукта/сервиса": "Product/service description",
  "Ключевые достижения компании": "Key achievements of the company",
  "Почему вы хотите принять участие в программе Local2Global?":
    "Why do you want to take part in the Local2Global program?",
  "Загрузить портфолио (в формате pdf, ppt не более 15 МБ)":
    "Upload portfolio (pdf, ppt format not exceeding 15 MB)",
  "Дополнительная информация": "Additional Information",
  "История действий по заявлению": "History of actions on the application",
  Исполнитель: "Executor",
  "Дата действия": "Valid date",
  "Статус действия": "Action status",
  "на русском": "in Russian",
  "на узбекском": "in Uzbek",
  "на английском": "in English",
  Отменить: "Cancel",
  Внимание: "Attention",
  "Заявки на участие": "Applications for participation",
  "Заявки на покрытие расходов": "Applications for expense coverage",
  "Дата начала договора (от)": "Дата начала договора (от)",
  "Дата начала договора (до)": "Дата начала договора (до)",
  "Возраст (от)": "Age (from)",
  "Возраст (до)": "Age (to)",
  "Гражданин Узбекистана": "Citizen of Uzbekistan",
  "Иностранный гражданин": "Foreign citizen",
  "Выберите сотрудника": "Select employee",
  "Передать в Экспертный совет": "Submit to the Expert Council",
  "Передано в Экспертный совет": "Referred to the Expert Council",
  Отклонён: "Rejected",
  "Член Экспертного совета": "Member of the Expert Council",
  "Дата и время решения": "Decision date and time",
  "Результаты рассмотрения Экспертного совета":
    "Results of the review by the Expert Council",
  "Экспертный совет рассмотрел": "Expert council reviewed",

  Степень: "Degree",
  Бакалавр: "Bachelor",
  Магистратура: "Master",
  "Название учебного заведения": "Name of educational institution",
  "Дата начала: месяц, год": "Start date: month, year",
  "Дата начала": "Start date",
  "Дата окончания: месяц, год": "End date: month, year",
  "Дата окончания": "End date",
  Специализация: "Specialization",
  Таблица: "Table",
  "Внесенные данные": "Entered data",
  "Внесение данных": "Entering data",
  "Имеется ли сотрудник со знанием английского языка? (Да/Нет)":
    "Is there a staff member who speaks English? (Not really)",
  "Внесенные данные в Local team": "Data entered into Local team",
  "Внести данные": "Enter data",
  "Изменение внесения данных": "Changing data entry",
  "Внесенные данные в Infrastructure": "Data entered in Infrastructure",
  "Название офиса": "Office name",
  "Площадь офиса": "Office area",
  "Аренда ($/m2)": "Rent ($/m2)",
  Мебель: "Furniture",
  Оборудование: "Equipment",
  "Локация офиса": "Office location",
  Район: "District",
  "Предоставил IT Park": "Provided by IT Park",
  "Предоставил хокимият": "Provided by the government",
  "Нету мебели": "No furniture",
  "Центр города": "City Center",
  "Окраина города": "Outskirts of the City",
  "Находится в районе": "Located in the District",
  "Подтвержденные данные": "Confirmed data",
  "Внесенные данные в Talents": "Data entered in Talents",
  "Подтвержденные данные в Talents": "Confirmed data in Talents",
  Направление: "Direction",
  Тип: "Type",
  Отклонено: "Rejected",
  Подтверждено: "Confirmed",
  "На рассмотрении": "Pending",
  "ФИО кандидата": "Full name of candidate",
  "Формирование резюме кандидата": "Formation of the candidate's resume",
  soft_skills: "Soft skills",
  hard_skills: "Hard skills",
  languages: "Languages",
  "Прикрипите ответственных сотрудников": "Attach responsible employees",
  'Контактные данные': 'Contact information',
  'Запросы в отдел Softlanding': 'Requests to the Softlanding department',
  'Перевести в отдел Softlanding': 'Transfer to the Softlanding department',
  'Направлен запрос в отдел Softlanding': 'Request has been sent to the Softlanding department',
  'Изменить этап': 'Edit step',
  'Редактирование этапа': 'Edit step',
};
